import React from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import * as UI from '@brewlogix/blx-ui'
import { CompositeSearchResult } from '../../types'
import parseItemPath from '../../utils/helpers/parseItemPath'
import liveSearchConfig from '../../config/liveSearchConfig'
import { CompositeProduct } from 'src/types/schema/graphql'

const LiveSearchResult: React.FC<{ item: CompositeSearchResult }> = ({ item }) => {
    const itemPath = parseItemPath(item) || ''
    if (
        item.__typename === 'Beer' ||
        item.__typename === 'Cider' ||
        item.__typename === 'Kombucha'
    ) {
        item = item as CompositeProduct
        if (item.isHiddenExternally === true) {
            return null
        }
    }
    return (
        <div
            className={classNames(
                'border-t-none last:border-b-none w-full overflow-hidden border-b-px border-b-gray-100 border-t-transparent  transition-all duration-200 ease-in last:rounded-b-xl last:border-b-transparent'
            )}
        >
            <Link href={itemPath}>
                <a>
                    <UI.Card
                        item={item}
                        format='inline'
                        customClasses='w-full rounded-none  bg-white hover:bg-gray-50 hover:text-black border-none'
                        image={
                            <UI.Icon
                                size='lg'
                                slug={liveSearchConfig.iconMap[item.__typename]}
                                theme='info'
                            />
                        }
                    />
                </a>
            </Link>
        </div>
    )
}

export default LiveSearchResult
